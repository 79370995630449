import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>
                        Vols estudiar d'una manera molt diferent escoltant tots els temes en àudio?
                    </strong>
                </p>

                <p>
                    Tots els{" "}
                    <strong>pòdcasts del temari per a l'accés a Mossos d'Esquadra 2024/25</strong>{" "}
                    amb totes les actualitzacions oficials de la guia.
                </p>

                <p>
                    En aquest curs trobaràs{" "}
                    <strong>
                        tot el temari necessari per a aprovar les teves oposicions a Mossos
                        d'Esquadra 2024/25
                    </strong>{" "}
                    en format de pòdcast!
                </p>

                <p>
                    <strong>No és simplement una lectura de temari.</strong> En aquests àudios
                    tindràs tots els temes esquematitzats, parlant dels punts clau més importants i
                    separats pels diferents àmbits i temes que indica la nostra "guia d'estudi".
                </p>

                <p>
                    <strong>Tindràs:</strong>
                </p>

                <ul>
                    <li>
                        <strong>Accés als vint pòdcasts</strong> de cada tema de Mossos d'Esquadra.
                    </li>
                    <li>
                        <strong>Gairebé 6 hores d'àudios</strong> perquè escoltis les vegades que
                        vulguis.
                    </li>
                    <li>
                        <strong>Narració pròxima i no monòtona</strong> per mantenir l'interès.
                    </li>
                    <li>
                        <strong>Música de fons</strong> per fer l'escolta més amena i motivadora.
                    </li>
                    <li>
                        <strong>Un superresum al final de cada pòdcast</strong> per a un repàs ràpid
                        de conceptes.
                    </li>
                </ul>

                <p>
                    <strong>
                        El millor de tot és que això està pensat perquè aprofitis el teu temps!
                    </strong>
                </p>

                <p>
                    <strong>Exemple de contingut del pòdcast:</strong>
                </p>

                <ul>
                    <li>Tema A.1. Història de Catalunya -part I- (21 min.)</li>
                    <li>Tema A.2. Història de Catalunya -part II- (26 min.)</li>
                    <li>Tema A.3. Història de la policia a Catalunya (16 min.)</li>
                    <li>Tema A.4. Àmbit sociolingüístic (20 min.)</li>
                    <li>Tema A.5. Marc geogràfic de Catalunya (13 min.)</li>
                    <li>Tema A.6. Entorn social a Catalunya (16 min.)</li>
                    <li>Tema A.7. Les tecnologies de la informació en el segle XXI. (12 min.)</li>
                    <li>Tema B.1. L'Estatut d'Autonomia de Catalunya (EAC) (21 min.)</li>
                    <li>Tema B.2. Les institucions polítiques de Catalunya (23 min.)</li>
                    <li>Tema B.3. L'ordenament jurídic de l'Estat (22 min.)</li>
                    <li>Tema B.4. Els drets humans i els drets constitucionals (24 min.)</li>
                    <li>Tema B.5. Les institucions polítiques de l'Estat (13 min.)</li>
                    <li>
                        Tema B.6. Els òrgans jurisdiccionals: Poder judicial i Tribunal
                        Constitucional (17 min.)
                    </li>
                    <li>Tema B.7. L'organització territorial de l'Estat (16 min.)</li>
                    <li>Tema B.8. La Unió Europea (25 min.)</li>
                    <li>
                        Tema C.1. Les competències de la Generalitat en matèria de seguretat (13
                        min.)
                    </li>
                    <li>Tema C.2. El Departament d'Interior</li>
                    <li>Tema C.3. La coordinació policial</li>
                    <li>Tema C.4. El marc legal de la seguretat</li>
                    <li>Tema C.5. El Codi deontològic policial</li>
                </ul>

                <p>
                    <strong>Pensat perquè aprofitis el temps</strong> repassant el teu temari mentre
                    entrenes o vas al treball!
                </p>

                <p>
                    Pots posar-te els auriculars i{" "}
                    <strong>
                        estudiar/repassar el temari mentre vas al treball, entrenes les teves proves
                        físiques o estàs netejant a casa!
                    </strong>
                </p>

                <p>
                    <strong>Comencem?</strong>
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Estudia per a les teves oposicions a Mossos d'Esquadra com mai abans! 🎧"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*
import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <>
            <ProductTemplate item={item}>
                <div className="container lg:px-0 py-20">
                    <Title>Pòdcast per l'accés a Mossos d'Esquadra 2024/25</Title>
                    <Text className="text-justify">
                        Vols estudiar d'una manera molt diferent escoltant tots els temes en àudio?
                        <br />
                        <br />
                        Tots el pòdcast del temari per l'accés a Mossos d'Esquadra 2024/25 amb totes
                        les actualitzacions oficials de la guia.
                        <br />
                        <br />
                        En aquest curs trobaràs tot el temari necessari per a aprovar les teves
                        oposicions a Mossos d'Esquadra 2024/25 en format de Pòdcast!
                        <br />
                        <br />
                        Però no estem parlant simplement d'una lectura de temari. En aquests àudios
                        tindràs tots els temes esquematitzats, parlant dels punts clau més
                        importants i separats pels diferents àmbits i temes que ens indica nostra
                        "guia d'estudi".
                        <br />
                        <br />
                        Tindràs:
                        <br />
                        <br />
                        Accés als vint pòdcasts de cada tema de Mossos d'Esquadra.
                        <br />
                        <br />
                        Gairebé 6 hores d'àudios perquè escoltis les vegades que vulguis.
                        <br />
                        <br />
                        Una narració pròxima i no monòtona.
                        <br />
                        <br />
                        Música de fons perquè l'escolta sigui més amena i motivadora.
                        <br />
                        <br />
                        Un superresum al final de cada pòdcast per a un repàs ràpid de conceptes. I
                        el millor de tot és que això està pensat perquè aprofitis el teu temps!
                        <br />
                        <br />
                        El pòdcast:
                        <br />
                        <br />
                        Tema A.1. Història de Catalunya -part I- (21 min.)
                        <br />
                        <br />
                        Tema A.2. Història de Catalunya -part II- (26 min.)
                        <br />
                        <br />
                        Tema A.3. Història de la policia a Catalunya (16 min.)
                        <br />
                        <br />
                        Tema A.4. Àmbit sociolingüístic (20 min.)
                        <br />
                        <br />
                        Tema A.5. Marc geogràfic de Catalunya (13 min.)
                        <br />
                        <br />
                        Tema A.6. Entorn social a Catalunya (16 min.)
                        <br />
                        <br />
                        Tema A.7. Les tecnologies de la informació en el segle xxi. (12 min.)
                        <br />
                        <br />
                        Tema B.1. L'Estatut d'Autonomia de Catalunya. (EAC) (21 min.)
                        <br />
                        <br />
                        Tema B.2. Les institucions polítiques de Catalunya. (23 min.)
                        <br />
                        <br />
                        Tema B.3. L'ordenament jurídic de l'Estat. (22 min.)
                        <br />
                        <br />
                        Tema B.4. Els drets humans i els drets constitucionals (24 min.)
                        <br />
                        <br />
                        Tema B.5. Les institucions polítiques de l'Estat. (13 min.)
                        <br />
                        <br />
                        Tema B.6. Els òrgans jurisdiccionals. Poder judicial i Tribunal
                        Constitucional (17 min.)
                        <br />
                        <br />
                        Tema B.7. L'organització territorial de l'Estat. (16 min.)
                        <br />
                        <br />
                        Tema B.8. La Unió Europea (25 min.)
                        <br />
                        <br />
                        Tema C.1. Les competències de la Generalitat en matèria de seguretat (13
                        min.)
                        <br />
                        <br />
                        Tema C.2. El Departament d'Interior.
                        <br />
                        <br />
                        Tema C.3. La coordinació policial.
                        <br />
                        <br />
                        Tema C.4. El marc legal de la seguretat.
                        <br />
                        <br />
                        Tema C.5. El Codi deontològic policial.
                        <br />
                        <br />
                        Pensat perquè aprofitis el temps repassant el teu temari mentre entrenes o
                        vas al treball!
                        <br />
                        <br />
                        Pots posar-te els auriculars i estudiar/repassar el temari mentre vas al
                        treball, mentre entrenes les teves proves físiques o mentre estàs netejant a
                        casa!                        
                        <br />
                        <br />
                        Comencem?
                    </Text>
                </div>
                
                <div className="bg-gray-100 bg-opacity-50">
                    <div className="container lg:px-0 py-20">
                        <Title>¿Cómo accedo?</Title>
                        <Text className="text-justify">
                            Una vez adquirido a través de la sección{" "}
                            <Link to="/tienda/" className="default-link">
                                Tienda
                            </Link>{" "}
                            recibirás un correo con tus credenciales para poder acceder al curso. En
                            el menú superior encontrarás la opción{" "}
                            <a
                                href="https://cursos.iopos.es"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="default-link"
                            >
                                Plataforma de cursos
                            </a>{" "}
                            que te dirigirá hacia la plataforma donde están almacenados todos los
                            cursos y formaciones.
                        </Text>
                    </div>
                </div>
            </ProductTemplate>
        </>
    );
};
export default Product;

*/
